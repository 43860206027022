/** Img */
img.text-xs-right{
  float: right;
  margin-left: 20px;
  margin-bottom: 15px;
}
img.text-xs-left{
  float: left;
  margin-right: 20px;
  margin-bottom: 15px;
}
img.text-xs-center{
  width: 100%;
  height: auto;
  margin: 10px 0;
}
