/** Synapse - adaptive theme
      .col-xs- <544px Extra small
$sm = .col-sm- ≥544px Small
$md = .col-md- ≥768px Medium
$lg = .col-lg- ≥992px Large
$xl = .col-xl- ≥1200px Extra large
Gutter width	1.875rem / 30px (15px on each side of a column) */
$sm: 544px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
