/** init forms Vars /scss/variables/_forms.scss */

$form-item-padding: 8px !default;

$input-bg:                white !default;
$input-border:            rgba(34,36,38,.15) !default;
$input-enable-shadows:    true !default;
$input-box-shadow:        0 0 0 0 transparent !default;
$input-focus-box-shadow:  0 0 0 2px rgba($brand-primary, .25) !default;
$input-border-radius:     $border-radius-small;

$input-bg-disabled:     rgb(250, 250, 250);
$input-color-disabled:  rgba(163, 163, 163, 1);
