/**
 * @file
 * Synapse - adaptive theme */
@import "variables/_index";

/**
 * Main Styles
 */
html {
  font-size: $font-size-base;
}
body {
  color: $body-color;
  background-color: $body-bg;
  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

/** Headers */
h1, h2, h3, h4, h5, h6 {
  font-family: $font-headers;
  font-weight: $font-headers-weight;
  margin-bottom: .5rem;
  line-height: 1.1em;
}
h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }

@import "typography/_link";
@import "typography/_table";
@import "typography/_img";
@import "typography/_blockquote";
@import "typography/_elements";
@import "typography/_button";
@import "typography/_modals";
