/** init colors Vars /scss/variables/_tables.scss */

// Padding for <th>s and <td>s.
$table-cell-padding: 8px;
// Padding for cells in .table-condensed.
$table-condensed-cell-padding: 5px;
// Default background color used for all tables.
$table-bg: transparent;
// Background color used for .table-striped.
$table-bg-accent: #f9f9f9;
// Background color used for .table-hover.
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;
// Border color for table and cell borders.
$table-border-color: #ddd;
