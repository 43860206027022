/** init buttons Vars /scss/variables/_buttons.scss */

$input-btn-padding-y:    8px !default;
$input-btn-padding-x:    16px !default;
$input-btn-line-height:  1.25 !default;
$input-btn-border-width: 1px !default;

// Small button
$input-btn-padding-y-sm:   4px !default;
$input-btn-padding-x-sm:   8px !default;
$input-btn-line-height-sm: 1.5 !default;

// Large buttons
$input-btn-padding-y-lg:   8px !default;
$input-btn-padding-x-lg:   16px !default;
$input-btn-line-height-lg: 1.5 !default;

$btn-font-weight:       normal !default;
$btn-enable-shadows:    true !default;
$btn-box-shadow:        0 1px 1px rgba(black, .125) !default;
$btn-focus-box-shadow:  0 0 0 2px rgba($brand-primary, .25) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba(black, .125) !default;
$btn-border-radius:     $border-radius-small !default;

$btn-default-color:  #333 !default;
$btn-default-bg:     #fff !default;
$btn-default-border: #ccc !default;

$btn-primary-color:  white !default;
$btn-primary-bg:     #2185d0 !default;
$btn-primary-border: transparent !default;

$btn-success-color:  white !default;
$btn-success-bg:     $brand-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-warning-color:  white !default;
$btn-warning-bg:     $brand-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-color:  white !default;
$btn-danger-bg:     $brand-error !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-info-color:  white !default;
$btn-info-bg:     $brand-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;
