a {
  color: $link-color;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

a:focus {
  outline: none !important;
}
